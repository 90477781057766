@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700;900&display=swap");
.grey-border {
  border-top: 1px solid grey;
  margin: 7px 0; }

.grey-border {
  border-top: 1px solid grey;
  margin: 7px 0; }

.flex-container {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 1em; }
  @media (min-width: 540px) {
    .flex-container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .flex-container {
      max-width: 768px; } }
  @media (min-width: 992px) {
    .flex-container {
      max-width: 992px; } }
  @media (min-width: 1200px) {
    .flex-container {
      max-width: 1200px; } }

.flex-row {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  justify-content: space-between; }
  .flex-row.flex-row--align-h-center {
    justify-content: center; }
  .flex-row.flex-row--align-v-column {
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap; }
  .flex-row.flex-row--align-v-center {
    align-items: center; }

.flex-row--spacebetween {
  justify-content: space-between; }

.flex-row--align-v-center {
  align-items: center; }

.flex-row--align-v-top {
  align-items: flex-start; }

.flex-row--align-v-bottom {
  align-items: flex-end; }

.flex-row--align-h-right {
  justify-content: flex-end; }

.flex-row--reverse {
  flex-direction: row-reverse; }

@media screen and (max-width: 600px) {
  .flex-col-reverse-mobile {
    flex-direction: column-reverse; } }

.flex-col-xs-1,
.flex-col-sm-1,
.flex-col-md-1,
.flex-col-lg-1 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-1.mod-centered,
  .flex-col-sm-1.mod-centered,
  .flex-col-md-1.mod-centered,
  .flex-col-lg-1.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-1.mod-bgimage,
  .flex-col-sm-1.mod-bgimage,
  .flex-col-md-1.mod-bgimage,
  .flex-col-lg-1.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-1.flex-direction-column,
  .flex-col-sm-1.flex-direction-column,
  .flex-col-md-1.flex-direction-column,
  .flex-col-lg-1.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-1 {
    width: calc(100% * (1 / 12)); } }

.flex-col-sm-1.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-1 {
    width: calc(100% * (1 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-1 {
    width: calc(100% * (1 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-1 {
    width: calc(100% * (1 / 12)); } }

.flex-col-xs-2,
.flex-col-sm-2,
.flex-col-md-2,
.flex-col-lg-2 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-2.mod-centered,
  .flex-col-sm-2.mod-centered,
  .flex-col-md-2.mod-centered,
  .flex-col-lg-2.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-2.mod-bgimage,
  .flex-col-sm-2.mod-bgimage,
  .flex-col-md-2.mod-bgimage,
  .flex-col-lg-2.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-2.flex-direction-column,
  .flex-col-sm-2.flex-direction-column,
  .flex-col-md-2.flex-direction-column,
  .flex-col-lg-2.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-2 {
    width: calc(100% * (2 / 12)); } }

.flex-col-sm-2.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-2 {
    width: calc(100% * (2 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-2 {
    width: calc(100% * (2 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-2 {
    width: calc(100% * (2 / 12)); } }

.flex-col-xs-3,
.flex-col-sm-3,
.flex-col-md-3,
.flex-col-lg-3 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-3.mod-centered,
  .flex-col-sm-3.mod-centered,
  .flex-col-md-3.mod-centered,
  .flex-col-lg-3.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-3.mod-bgimage,
  .flex-col-sm-3.mod-bgimage,
  .flex-col-md-3.mod-bgimage,
  .flex-col-lg-3.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-3.flex-direction-column,
  .flex-col-sm-3.flex-direction-column,
  .flex-col-md-3.flex-direction-column,
  .flex-col-lg-3.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-3 {
    width: calc(100% * (3 / 12)); } }

.flex-col-sm-3.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-3 {
    width: calc(100% * (3 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-3 {
    width: calc(100% * (3 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-3 {
    width: calc(100% * (3 / 12)); } }

.flex-col-xs-4,
.flex-col-sm-4,
.flex-col-md-4,
.flex-col-lg-4 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-4.mod-centered,
  .flex-col-sm-4.mod-centered,
  .flex-col-md-4.mod-centered,
  .flex-col-lg-4.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-4.mod-bgimage,
  .flex-col-sm-4.mod-bgimage,
  .flex-col-md-4.mod-bgimage,
  .flex-col-lg-4.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-4.flex-direction-column,
  .flex-col-sm-4.flex-direction-column,
  .flex-col-md-4.flex-direction-column,
  .flex-col-lg-4.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-4 {
    width: calc(100% * (4 / 12)); } }

.flex-col-sm-4.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-4 {
    width: calc(100% * (4 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-4 {
    width: calc(100% * (4 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-4 {
    width: calc(100% * (4 / 12)); } }

.flex-col-xs-5,
.flex-col-sm-5,
.flex-col-md-5,
.flex-col-lg-5 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-5.mod-centered,
  .flex-col-sm-5.mod-centered,
  .flex-col-md-5.mod-centered,
  .flex-col-lg-5.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-5.mod-bgimage,
  .flex-col-sm-5.mod-bgimage,
  .flex-col-md-5.mod-bgimage,
  .flex-col-lg-5.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-5.flex-direction-column,
  .flex-col-sm-5.flex-direction-column,
  .flex-col-md-5.flex-direction-column,
  .flex-col-lg-5.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-5 {
    width: calc(100% * (5 / 12)); } }

.flex-col-sm-5.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-5 {
    width: calc(100% * (5 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-5 {
    width: calc(100% * (5 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-5 {
    width: calc(100% * (5 / 12)); } }

.flex-col-xs-6,
.flex-col-sm-6,
.flex-col-md-6,
.flex-col-lg-6 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-6.mod-centered,
  .flex-col-sm-6.mod-centered,
  .flex-col-md-6.mod-centered,
  .flex-col-lg-6.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-6.mod-bgimage,
  .flex-col-sm-6.mod-bgimage,
  .flex-col-md-6.mod-bgimage,
  .flex-col-lg-6.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-6.flex-direction-column,
  .flex-col-sm-6.flex-direction-column,
  .flex-col-md-6.flex-direction-column,
  .flex-col-lg-6.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-6 {
    width: calc(100% * (6 / 12)); } }

.flex-col-sm-6.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-6 {
    width: calc(100% * (6 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-6 {
    width: calc(100% * (6 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-6 {
    width: calc(100% * (6 / 12)); } }

.flex-col-xs-7,
.flex-col-sm-7,
.flex-col-md-7,
.flex-col-lg-7 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-7.mod-centered,
  .flex-col-sm-7.mod-centered,
  .flex-col-md-7.mod-centered,
  .flex-col-lg-7.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-7.mod-bgimage,
  .flex-col-sm-7.mod-bgimage,
  .flex-col-md-7.mod-bgimage,
  .flex-col-lg-7.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-7.flex-direction-column,
  .flex-col-sm-7.flex-direction-column,
  .flex-col-md-7.flex-direction-column,
  .flex-col-lg-7.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-7 {
    width: calc(100% * (7 / 12)); } }

.flex-col-sm-7.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-7 {
    width: calc(100% * (7 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-7 {
    width: calc(100% * (7 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-7 {
    width: calc(100% * (7 / 12)); } }

.flex-col-xs-8,
.flex-col-sm-8,
.flex-col-md-8,
.flex-col-lg-8 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-8.mod-centered,
  .flex-col-sm-8.mod-centered,
  .flex-col-md-8.mod-centered,
  .flex-col-lg-8.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-8.mod-bgimage,
  .flex-col-sm-8.mod-bgimage,
  .flex-col-md-8.mod-bgimage,
  .flex-col-lg-8.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-8.flex-direction-column,
  .flex-col-sm-8.flex-direction-column,
  .flex-col-md-8.flex-direction-column,
  .flex-col-lg-8.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-8 {
    width: calc(100% * (8 / 12)); } }

.flex-col-sm-8.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-8 {
    width: calc(100% * (8 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-8 {
    width: calc(100% * (8 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-8 {
    width: calc(100% * (8 / 12)); } }

.flex-col-xs-9,
.flex-col-sm-9,
.flex-col-md-9,
.flex-col-lg-9 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-9.mod-centered,
  .flex-col-sm-9.mod-centered,
  .flex-col-md-9.mod-centered,
  .flex-col-lg-9.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-9.mod-bgimage,
  .flex-col-sm-9.mod-bgimage,
  .flex-col-md-9.mod-bgimage,
  .flex-col-lg-9.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-9.flex-direction-column,
  .flex-col-sm-9.flex-direction-column,
  .flex-col-md-9.flex-direction-column,
  .flex-col-lg-9.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-9 {
    width: calc(100% * (9 / 12)); } }

.flex-col-sm-9.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-9 {
    width: calc(100% * (9 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-9 {
    width: calc(100% * (9 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-9 {
    width: calc(100% * (9 / 12)); } }

.flex-col-xs-10,
.flex-col-sm-10,
.flex-col-md-10,
.flex-col-lg-10 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-10.mod-centered,
  .flex-col-sm-10.mod-centered,
  .flex-col-md-10.mod-centered,
  .flex-col-lg-10.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-10.mod-bgimage,
  .flex-col-sm-10.mod-bgimage,
  .flex-col-md-10.mod-bgimage,
  .flex-col-lg-10.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-10.flex-direction-column,
  .flex-col-sm-10.flex-direction-column,
  .flex-col-md-10.flex-direction-column,
  .flex-col-lg-10.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-10 {
    width: calc(100% * (10 / 12)); } }

.flex-col-sm-10.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-10 {
    width: calc(100% * (10 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-10 {
    width: calc(100% * (10 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-10 {
    width: calc(100% * (10 / 12)); } }

.flex-col-xs-11,
.flex-col-sm-11,
.flex-col-md-11,
.flex-col-lg-11 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-11.mod-centered,
  .flex-col-sm-11.mod-centered,
  .flex-col-md-11.mod-centered,
  .flex-col-lg-11.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-11.mod-bgimage,
  .flex-col-sm-11.mod-bgimage,
  .flex-col-md-11.mod-bgimage,
  .flex-col-lg-11.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-11.flex-direction-column,
  .flex-col-sm-11.flex-direction-column,
  .flex-col-md-11.flex-direction-column,
  .flex-col-lg-11.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-11 {
    width: calc(100% * (11 / 12)); } }

.flex-col-sm-11.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-11 {
    width: calc(100% * (11 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-11 {
    width: calc(100% * (11 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-11 {
    width: calc(100% * (11 / 12)); } }

.flex-col-xs-12,
.flex-col-sm-12,
.flex-col-md-12,
.flex-col-lg-12 {
  box-sizing: border-box;
  padding: 1em;
  width: 100%; }
  .flex-col-xs-12.mod-centered,
  .flex-col-sm-12.mod-centered,
  .flex-col-md-12.mod-centered,
  .flex-col-lg-12.mod-centered {
    justify-content: center;
    display: flex; }
  .flex-col-xs-12.mod-bgimage,
  .flex-col-sm-12.mod-bgimage,
  .flex-col-md-12.mod-bgimage,
  .flex-col-lg-12.mod-bgimage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .flex-col-xs-12.flex-direction-column,
  .flex-col-sm-12.flex-direction-column,
  .flex-col-md-12.flex-direction-column,
  .flex-col-lg-12.flex-direction-column {
    flex-direction: column; }

@media (min-width: 540px) {
  .flex-col-xs-12 {
    width: calc(100% * (12 / 12)); } }

.flex-col-sm-12.no-padding {
  padding: 0; }

@media (min-width: 768px) {
  .flex-col-sm-12 {
    width: calc(100% * (12 / 12)); } }

@media (min-width: 992px) {
  .flex-col-md-12 {
    width: calc(100% * (12 / 12)); } }

@media (min-width: 1200px) {
  .flex-col-lg-12 {
    width: calc(100% * (12 / 12)); } }

html, body {
  overflow-x: hidden; }

body {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  margin: 0; }

a {
  cursor: pointer; }

ul {
  padding-left: 0px;
  list-style: none; }

button {
  padding: 10px 30px;
  background-color: #8467ad;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.45px;
  text-decoration: none;
  min-width: 158px; }
  button:hover, button:focus {
    background-color: #8467ad; }

.banner-spacer {
  margin-top: 40px; }
  @media screen and (max-width: 600px) {
    .banner-spacer {
      margin-top: 0px; } }

.banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 600px) {
    .banner {
      margin-top: 7rem; } }
  .banner img {
    height: 100%;
    width: auto; }
    @media screen and (max-width: 600px) {
      .banner img {
        width: 100%; } }

.headerlink {
  font-size: 0.85em;
  font-family: "Open Sans", sans-serif;
  border-bottom: 1px solid #ea3e97; }

h1, h2, h3, p {
  font-family: "Open Sans", sans-serif; }

h1 {
  font-size: 3rem; }

h2 {
  font-size: 2rem; }
  h2.text_pink {
    color: #e00a74; }

p {
  font-size: 1rem;
  font-weight: 600; }

::selection {
  color: #fff;
  background: #ea3e97; }

::-moz-selection {
  color: #fff;
  background: #ea3e97; }

#root {
  position: relative; }
