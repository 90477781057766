@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700;900&display=swap");
.grey-border {
  border-top: 1px solid grey;
  margin: 7px 0; }

.loading {
  padding: 5rem;
  font-family: "Open Sans", sans-serif;
  font-size: 3rem;
  color: #363b3b;
  text-align: center;
  animation: Pulse 1s infinite; }

.wrap {
  max-width: 1100px;
  margin: 0 auto; }
  .wrap.nav {
    width: 100%; }

.wrap_large {
  max-width: 1920px;
  margin: 0 auto; }

.content {
  padding: 145px 0 0 0;
  margin-bottom: 10rem; }
  @media screen and (max-width: 1100px) {
    .content {
      padding: 105px 0 0 0; } }
  @media screen and (max-width: 600px) {
    .content {
      padding-top: 0px; } }

.imageclass {
  width: 100%;
  height: auto; }

@keyframes Pulse {
  0% {
    color: #e2e2e2; }
  50% {
    color: #363b3b; }
  100% {
    color: #e2e2e2; } }
