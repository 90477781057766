@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700;900&display=swap");
.grey-border {
  border-top: 1px solid grey;
  margin: 7px 0; }

.interstitial {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(102, 206, 246, 0.9);
  min-height: 100vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: middle;
  width: 100%; }
  .interstitial .interstitial-content-iframe {
    position: relative;
    max-width: 70% !important;
    margin: 0 auto;
    font-size: 1.5rem;
    border: 10px solid transparent;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 15px; }
    @media screen and (max-width: 600px) {
      .interstitial .interstitial-content-iframe {
        max-width: 85% !important; } }
  .interstitial .interstitial-content {
    position: relative;
    max-width: 40%;
    margin: 0 auto;
    font-size: 1.5rem;
    border: 10px solid transparent;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 15px; }
    @media screen and (max-width: 600px) {
      .interstitial .interstitial-content {
        max-width: 60%; } }
    .interstitial .interstitial-content h2 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.05px;
      font-weight: 700;
      color: #8467ad; }
    .interstitial .interstitial-content p {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.05px;
      font-weight: 500; }
    .interstitial .interstitial-content .interstitial-close {
      width: 45px;
      height: 45px;
      background-image: url("./Images/Close-Default.png");
      background-size: contain;
      position: absolute;
      top: -1.5rem;
      right: -1.5rem; }
      .interstitial .interstitial-content .interstitial-close:hover {
        background-image: url("./Images/Close-Hover.png"); }
    .interstitial .interstitial-content .interstitial-close-iframe {
      top: -23rem !important;
      right: -23rem !important; }
  .interstitial .interstial-links a {
    display: inline-block;
    padding: .5rem 1rem;
    margin: 0 .5rem .5rem 0;
    background: #ea3e97;
    text-decoration: none;
    border-radius: 50px;
    transition: background .5s;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 12px 66px 12px 67px; }
    .interstitial .interstial-links a:nth-child(2) {
      background: #ea3e97; }
    .interstitial .interstial-links a:hover {
      background: #8467ad; }

iframe, object, embed {
  max-width: 100%; }
