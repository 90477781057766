@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700;900&display=swap");
.grey-border {
  border-top: 1px solid grey;
  margin: 7px 0; }

header {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 10000;
  background: #ea3e97;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1); }
  header .pink_swoosh_container {
    width: 100%;
    position: absolute;
    z-index: -1; }
    header .pink_swoosh_container img {
      display: none; }
      @media screen and (max-width: 1920px) {
        header .pink_swoosh_container img {
          max-width: 100%;
          display: block; } }
      @media screen and (max-width: 1100px) {
        header .pink_swoosh_container img {
          display: none; } }
      @media screen and (max-width: 600px) {
        header .pink_swoosh_container img {
          display: none; } }
  header.active {
    height: 100%;
    background-image: linear-gradient(#ea3e97, #ea3e97, #f7f7f7); }
    header.active .localebar {
      display: none; }
    header.active .main-menu {
      height: 100vh;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0px; }
    header.active .main-menu__links {
      padding-top: 88.88889px;
      width: 100%;
      height: 80%;
      display: flex;
      margin: 0px; }
      @media screen and (max-width: 600px) {
        header.active .main-menu__links {
          padding-top: 20px; } }
      header.active .main-menu__links ul {
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: space-around; }
        header.active .main-menu__links ul li {
          width: 100%;
          justify-content: center;
          display: flex;
          align-items: center; }
          header.active .main-menu__links ul li a {
            text-decoration: none; }
            @media screen and (max-width: 600px) {
              header.active .main-menu__links ul li a {
                padding: 0.5rem 0; } }
            header.active .main-menu__links ul li a span {
              font-size: 2.5em;
              color: white; }
              header.active .main-menu__links ul li a span:active {
                color: #001e53; }
  header.mod-hidden .pink_swoosh_container {
    display: none; }
  header.mod-hidden .localebar {
    height: 0px;
    padding: 0px; }
  header.mod-hidden .localebar__loc__icon {
    height: 0px; }
  header.mod-hidden .localebar__loc__select {
    display: none; }
  header.mod-hidden .main-menu__logo {
    margin: 0 1rem 0 0 !important;
    margin-top: 50px; }
    header.mod-hidden .main-menu__logo .mainlogo {
      height: 114.28571px; }
    @media screen and (max-width: 1100px) {
      header.mod-hidden .main-menu__logo .mainlogo {
        display: none; }
      header.mod-hidden .main-menu__logo .mobilelogo {
        display: block; } }
  @media screen and (max-width: 1100px) and (max-width: 600px) {
    header.mod-hidden .main-menu__logo .mobilelogo {
      top: -45px !important; } }
  header.mod-hidden .main-menu__toggle {
    top: 25px; }

.localebar {
  width: 100%;
  background: white;
  padding: 0.8rem 0rem;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  transition: all 250ms; }

.localebar__loc {
  height: 100%;
  display: flex;
  justify-content: flex-end; }
  @media screen and (max-width: 1100px) {
    .localebar__loc {
      padding-right: 15px; } }

.localebar__loc__icon {
  margin: 0 5px;
  transition: all 250ms; }

.localebar__loc__select {
  background: lightgray;
  border: 0px solid white;
  border-radius: 0px;
  transition: all 250ms; }
  .localebar__loc__select option {
    border-radius: 0px;
    padding: 2px; }

.main-menu-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .main-menu-wrapper .main-menu {
    display: flex;
    justify-content: flex-start;
    color: white;
    align-items: center;
    height: 5rem;
    padding: 10px 0px; }
    @media screen and (max-width: 1100px) {
      .main-menu-wrapper .main-menu {
        height: 3rem; } }
    .main-menu-wrapper .main-menu .main-menu__logo {
      margin: 4rem 1rem 0 0; }
    .main-menu-wrapper .main-menu.centered {
      justify-content: center;
      margin-left: -200px; }
      .main-menu-wrapper .main-menu.centered .main-menu__links {
        margin: 0px; }
      .main-menu-wrapper .main-menu.centered .main-menu__logo {
        margin: 0px; }
  .main-menu-wrapper .social-icons a {
    margin: 0 0.35rem; }
    .main-menu-wrapper .social-icons a img {
      width: 2rem; }
  @media screen and (max-width: 1100px) {
    .main-menu-wrapper .social-icons {
      display: none; } }

.main-menu__logo {
  position: relative;
  cursor: pointer; }
  .main-menu__logo .mainlogo, .main-menu__logo .mobilelogo {
    height: 200px;
    transition: all 250ms;
    width: auto; }
  .main-menu__logo .mobilelogo {
    display: none;
    position: absolute; }
  @media screen and (max-width: 1100px) {
    .main-menu__logo .mainlogo {
      display: none; }
    .main-menu__logo .mobilelogo {
      display: block;
      height: 80px;
      top: -75px; } }

@media screen and (max-width: 1100px) {
  .main-menu__links {
    display: none; } }

.main-menu__links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%; }
  .main-menu__links ul li {
    float: left; }
    .main-menu__links ul li.mobile {
      display: none; }
      @media screen and (max-width: 1100px) {
        .main-menu__links ul li.mobile {
          display: block; } }
    .main-menu__links ul li a {
      padding: 2rem;
      padding-right: 1rem;
      font-size: 1rem;
      color: black;
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      text-decoration: none;
      cursor: pointer; }
      @media screen and (max-width: 1100px) {
        .main-menu__links ul li a {
          font-size: .8rem; } }
      .main-menu__links ul li a:hover {
        color: white;
        background-image: url("./Images/Pink-HoverStroke.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; }

.main-menu__loc {
  margin: 0 1rem 0 0; }

.main-menu__toggle {
  cursor: pointer;
  color: white;
  display: none;
  font-size: 2.8em;
  position: absolute;
  top: 65px;
  right: 25px; }
  .main-menu__toggle .bars {
    display: block; }
    .main-menu__toggle .bars.active {
      display: none; }
  .main-menu__toggle .exit {
    display: none; }
    .main-menu__toggle .exit.active {
      display: block; }
  @media screen and (max-width: 1100px) {
    .main-menu__toggle {
      display: block; } }

.social-icon-mobile {
  display: none; }
  @media screen and (max-width: 1100px) {
    .social-icon-mobile {
      display: block; } }
  @media screen and (max-width: 600px) {
    .social-icon-mobile a {
      margin: 0 1rem; }
      .social-icon-mobile a img {
        width: 3rem; } }
