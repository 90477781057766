@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700;900&display=swap");
.grey-border {
  border-top: 1px solid grey;
  margin: 7px 0; }

.footer {
  bottom: 0px;
  padding: 1rem 0;
  background-color: #8467ad; }
  @media screen and (max-width: 600px) {
    .footer {
      display: flex; } }
  .footer .wrap {
    position: relative;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 600px) {
      .footer .wrap {
        flex-direction: column; } }

.purple_swoosh_container {
  width: 100%;
  position: absolute;
  z-index: -1;
  bottom: 0; }
  .purple_swoosh_container img {
    display: none; }
    @media screen and (max-width: 1920px) {
      .purple_swoosh_container img {
        max-width: 100%;
        display: block; } }
    @media screen and (max-width: 600px) {
      .purple_swoosh_container img {
        display: none; } }

.footer__logo {
  margin: 0.25rem 1rem 0 0; }
  .footer__logo img {
    height: 50px; }

.footer__logo {
  margin: 0.25rem 1rem 0 0; }
  .footer__logo img {
    height: 50px; }

.footer__links a {
  margin: 0 1rem 0 0;
  color: white;
  text-decoration: none; }

@media screen and (max-width: 600px) {
  .footer__links {
    text-align: center; } }

.footer__copyright {
  position: absolute;
  right: 0;
    right-margin: 0 1rem 0 4rem;
  color: white;
  font-size: 0.75em; }
  @media screen and (max-width: 600px) {
    .footer__copyright {
      position: relative;
      margin: 1rem 0; } }
